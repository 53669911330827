import SwiperProductMenu from '../../components/client/SwiperProductMenu'
import PopularMerchants from '../../components/client/PopularMerchants'
import { LineAds, RandomSizeAds } from '../../components/other/Ads'
import { ProductContext } from '../../context/ProductContext'
import { UserContext } from '../../context/UserContext'
import Footer from '../../components/client/Footer'
import Header from '../../components/client/Header'
import React, { useContext, useEffect } from 'react'

import '../../styles/client/page/Home.css'
import '../../styles/client/page/MerchantPage.css'
import { SvgMessage, SvgShare } from '../../assets/icons/Svgs'

export default function MerchantPage() {

  const { productList } = useContext(ProductContext);
  const { userInformation } = useContext(UserContext);

  // window.location.search.replace('?id=', '')

  useEffect(() => {

  }, [])



  return (
    <>
      <LineAds />

      <Header productList={productList} />

      <div className="profile-header">
        <img src="https://plus.unsplash.com/premium_photo-1723826751660-717811d0fbc6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Profile Picture" className="profile-picture" />

        <div className="merchant_info_container">
          <div className="profile-info">
            <h2 className="profile-name">Lucary</h2>
            <div className="popularity-rating">
              <span className="stars">★★★★☆</span>
            </div>
            <div className='profile-actions'>
              <div className="follower-count">867 takipci</div>
              <div className="follower-count">|</div>
              <div className="product-count">56 toplam satilmis mehsul</div>
            </div>
          </div>
          <div className="profile-actions">
            <button className="follow-btn">Takib Et</button>
            <button className="share-btn"><SvgShare /></button>
            <button onClick={() => window.location.href = 'lucary-direct'} className="contact-btn"><SvgMessage /></button>
          </div>
        </div>
      </div>


      {
        productList?.map((p) => (
          <SwiperProductMenu p={p} />
        ))
      }

      {/* <RandomSizeAds /> */}

      {!userInformation.isMobile && <Footer />}

    </>
  )
}
