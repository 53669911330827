import React from 'react'
import { SvgCategories } from '../../assets/icons/Svgs'

export default function LeftBar() {
    return (
        <div className='user_profile_left_bar __minimize'>
            <div>
                <div onClick={() => window.location.href = '/merchant-orders'} className={window.location.href.includes('merchant-orders') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Sifarislerim</p>
                </div>
                <div onClick={() => window.location.href = '/merchat-messages'} className={window.location.href.includes('merchat-messages') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Mesajlar</p>
                </div>
                <div onClick={() => window.location.href = '/merchat-add-product'} className={window.location.href.includes('merchat-add-product') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Yeni Mehsul elave ets</p>
                </div>
                <div onClick={() => window.location.href = '/merchat-products'} className={window.location.href.includes('merchat-products') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Mehsullar</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Suallari</p>
                </div>
            </div>

            <div>
                <div>
                    <SvgCategories />
                    <p>Endirim kuponlarim</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Takibci statistikasi</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Trend mehsullar</p>
                </div>
            </div>

            <div>
                <div>
                    <SvgCategories />
                    <p>Lucary eventler</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Cekilisler</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Yarismalar</p>
                </div>
            </div>

            <div>
                <div onClick={() => window.location.href = '/merchant-information'} className={window.location.href.includes('merchant-information') ? '__active' : ''}>
                    <SvgCategories />
                    <p>Istifadeci melumatlarim</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Address melumatlari</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Odenis Metodlari</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Bildiris secimleri</p>
                </div>
                <div>
                    <SvgCategories />
                    <p>Destek</p>
                </div>
            </div>
        </div>
    )
}
