import { getAllProductApi, getProductByIdApi, HOSTPRODUCT } from "../../server/api";
import ErrorAlert from "../other/ErrorAlert";

export const GetProductList = async () => {
    let token = window.localStorage.getItem('lucary-token')
    
    if (token !== null) {
        if (window.localStorage.getItem('role') !== 'MERCHANT') {
            try {
                const response = await fetch(HOSTPRODUCT + getAllProductApi, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                        "Access-Control-Allow-Headers": "Content-Type",
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json'
                    }
                });
    
                if (!response.ok) {
                    ErrorAlert({ response: response, jwt: token })
                } else {
                    let data = await response?.text()
                    return data
                }
    
            } catch (error) {
                console.error('Error GetProductList: ', error);
            }
        }
    }
}

export const GetProductById = async ({ jwt, itemID }) => {
    try {
        const response = await fetch(HOSTPRODUCT + getProductByIdApi + itemID, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${jwt}`,
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            }
        });

        return await response.json()
    } catch (error) {
        console.error('Error GetProductById: ', error);
    }
}