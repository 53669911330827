import React, { useContext } from 'react'
import '../../styles/component/secondary/Ads.css'
import { UserContext } from '../../context/UserContext';

export function LineAds() {
  const { userInformation } = useContext(UserContext);

  return !userInformation.isMobile ? (
    <div className='line_ads'>
      <img src='https://s.alicdn.com/@img/imgextra/i3/O1CN01Rn1Jyg1y95G721PM9_!!6000000006535-2-tps-3840-80.png' />
    </div>
  ) : undefined
}

export function RandomSizeAds() {

  const { userInformation } = useContext(UserContext);

  const rectangles = [
    { img: 'https://firebasestorage.googleapis.com/v0/b/lucary-test-323fc.appspot.com/o/banners%2FMor%20Sar%C4%B1%20Al%C4%B1%C5%9Fveri%C5%9F%20%C4%B0ndirim%20F%C4%B1rsatlar%C4%B1%20Sale%20Instagram%20G%C3%B6nderisi.gif?alt=media&token=61277393-f8bc-4039-bdd4-65c10c5e4547' },
    { img: 'https://firebasestorage.googleapis.com/v0/b/lucary-test-323fc.appspot.com/o/banners%2Fads.gif?alt=media&token=1c019cc9-030a-4fe3-9cd4-e89c256171a1' },
    { img: 'https://firebasestorage.googleapis.com/v0/b/lucary-test-323fc.appspot.com/o/banners%2Fads.gif?alt=media&token=1c019cc9-030a-4fe3-9cd4-e89c256171a1' },
    { img: 'https://firebasestorage.googleapis.com/v0/b/lucary-test-323fc.appspot.com/o/banners%2Fad.gif?alt=media&token=a59e13bf-4b01-4301-b24b-f1e953bbd465' },
    { img: 'https://firebasestorage.googleapis.com/v0/b/lucary-test-323fc.appspot.com/o/banners%2Fad.gif?alt=media&token=a59e13bf-4b01-4301-b24b-f1e953bbd465' }
  ];

  return (
    userInformation.isMobile
      ?
      <div>
        <div className='ads_container'>
          <img src={rectangles[0].img} />
        </div>
      </div>
      :
      <div className="random_size_container">
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div className="random_size_rectangle rectangle1">
            <img src={rectangles[0].img} />
          </div>

          <div className="side_by_side">
            <div className="random_size_rectangle rectangle2">
              <img src={rectangles[1].img} />
            </div>
            <div className="random_size_rectangle rectangle3">
              <img src={rectangles[2].img} />
            </div>
          </div>
        </div>

        <div className="stacked">
          <div className="random_size_rectangle rectangle4">
            <img src={rectangles[3].img} />
          </div>
          <div className="random_size_rectangle rectangle5">
            <img src={rectangles[4].img} />
          </div>
        </div>
      </div>
  )
}