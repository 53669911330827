import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import { router } from './Router';
import { UserContext, UserProvider } from './context/UserContext';
import { ProductProvider } from './context/ProductContext';
import { ToastContainer } from 'react-toastify';

const App = () => {

  const { userInformation } = useContext(UserContext);

  return (
    <React.StrictMode>
      {
        userInformation === undefined
          ?
          <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#3EAFE4',
            justifyContent: 'center',
          }}>
            <ToastContainer />
            <img style={{ width: 100, height: 100, objectFit: 'cover' }} src={require('../src/assets/gifs/loading.gif')} />
          </div>
          :
          <RouterProvider router={router} />
      }
    </React.StrictMode >
  );
}

const Root = () => (
  <ProductProvider>
    <UserProvider>
      <App />
    </UserProvider>
  </ProductProvider>
);

ReactDOM.createRoot(document.getElementById("root")).render(<Root />);
