import React, { useContext, useEffect, useState } from 'react'
import { SvgHeart, SvgMessage, SvgStar } from '../../assets/icons/Svgs'
import { ToogleCart } from '../../functions/client/Toogle/ToogleCart';
import { GetProductById } from '../../functions/client/GetProductList';
import { UserContext } from '../../context/UserContext';
import { GetUserBasket } from '../../functions/client/Get/GetUserBasket';
import { ToogleFavList } from '../../functions/client/Toogle/ToogleFavList';

export default function SingleItemContainer() {

    const [singleProduct, setSingleProduct] = useState(undefined)
    const { userInformation, setUserInformation } = useContext(UserContext);

    useEffect(() => {
        const searchedItem = new URLSearchParams(window.location.search);
        const itemID = searchedItem.get('item');

        GetProductById({ itemID: itemID, jwt: userInformation?.jwt }).then(response => setSingleProduct(response))

        GetUserBasket({ jwt: userInformation?.jwt }).then(response => {
            setUserInformation({ ...userInformation, basket: response })
        })
    }, [])

    return !userInformation.isMobile ? (
        <div className='single_item_container'>
            <div className='item_info'>
                <h2 className='item_name'>{singleProduct?.title}</h2>
                <p className='item_category'>{singleProduct?.categoryName}</p>
                <p className='item_author'>{singleProduct?.companyName} · {singleProduct?.authorName}</p>
            </div>

            <div className='item_main_section'>
                <div className='item_image_section'>
                    <div className='item_mini_image_list'>
                        {
                            singleProduct?.imgUrl.map((img, key) => (
                                <img key={key} src={img} />
                            ))
                        }
                    </div>
                    <div className='item_image'>
                        <div className={!userInformation?.myFavProducts?.includes(singleProduct?.id) ? 'add_fav' : 'remove_fav'} onClick={() => ToogleFavList({ singleProduct, userInformation }).then(response => response && setUserInformation({ ...userInformation, myFavProducts: [...userInformation?.myFavProducts, singleProduct?.id] }))}><SvgHeart /></div>
                        <img src={singleProduct?.imgUrl[0]} />
                    </div>
                </div>

                <div className='item_add_cart_section'>
                    <div className='seller_details'>
                        <div className='seller_name' onClick={() => window.location.href = `/company-page?id=${singleProduct?.companyId}`}>
                            <img src='//live.staticflickr.com/65535/53865111500_4ed3d3517f_k.jpg' />
                            <h3>{singleProduct?.companyName}</h3>
                        </div>
                        <div className='seller_raiting'>
                            <div>{singleProduct?.rating} ★</div>
                            <h3>Uğurlu sifariş</h3>
                        </div>
                    </div>

                    <div className='item_interactions'>
                        <div>
                            <h3>Sual ver</h3>
                            <SvgMessage />
                        </div>
                    </div>

                    <div className='item_price_info'>
                        <h2 className='item_price'>{singleProduct?.price} AZN</h2>
                        {/* <h2 className='item_discount_price'>35 AZN</h2> */}
                    </div>

                    <div className='add_cart_btn'>
                        {
                            <h3 className={userInformation?.basket?.includes(singleProduct?.id) ? 'in_cart' : ''} onClick={() => {
                                if (userInformation?.basket?.includes(singleProduct?.id)) {
                                    window.location.href = '/basket'
                                } else {
                                    ToogleCart({ userInformation, singleProduct, inBasket: userInformation?.basket?.includes(singleProduct?.id), setUserInformation })
                                }
                            }}>{userInformation?.basket?.includes(singleProduct?.id) ? 'Səbətə keç' : 'Səbətə əlavə et'}</h3>
                        }
                    </div>

                    <div className='custom_order'>
                        <h3>Özəlləşdir</h3>
                        <p>Bu sifarişi özəlləşdirə bilərsiniz, bunun üçün satıcı ilə əlaqəyə keçin</p>
                    </div>
                </div>
            </div>
        </div>
    )
        :
        <div className='single_item_mobile_container'>
            <div className='item_info'>
                <h2 className='item_name'>{singleProduct?.title}</h2>
                <p className='item_category'>{singleProduct?.categoryName}</p>
                <p className='item_author'>{singleProduct?.companyName} · {singleProduct?.authorName}</p>
            </div>

            <div className='item_image'>
                <img src={singleProduct?.imgUrl[0]} />
            </div>

            <div className='item_mini_image_list'>
                {
                    singleProduct?.imgUrl.map((img, key) => (
                        <img key={key} src={img} />
                    ))
                }
            </div>

            <div className='item_interactions'>
                <div>
                    <h3>Favorilərə əlavə et</h3>
                    <SvgHeart />
                </div>
                <div>
                    <h3>Sual ver</h3>
                    <SvgMessage />
                </div>
            </div>

            <div className='item_price_info'>
                <h2 className='item_price'>{singleProduct?.price} AZN</h2>
                {/* <h2 className='item_discount_price'>35 AZN</h2> */}
            </div>

            <div className='add_cart_btn'>
                {
                    <h3 className={userInformation?.basket?.includes(singleProduct?.id) ? 'in_cart' : ''} onClick={() => {
                        if (userInformation?.basket?.includes(singleProduct?.id)) {
                            window.location.href = '/basket'
                        } else {
                            ToogleCart({ userInformation, singleProduct, inBasket: userInformation?.basket?.includes(singleProduct?.id), setUserInformation })
                        }
                    }}>{userInformation?.basket?.includes(singleProduct?.id) ? 'Səbətə keç' : 'Səbətə əlavə et'}</h3>
                }
            </div>

            <div className='custom_order'>
                <p>Bu sifarişi özəlləşdirə bilərsiniz, bunun üçün satıcı ilə əlaqəyə keçin</p>
                <h3>Özəlləşdir</h3>
            </div>

            <div className='seller_details'>
                <div onClick={() => window.location.href = `/company-page?id=${singleProduct?.companyId}`}> className='seller_name'>
                    <img src='//live.staticflickr.com/65535/53865111500_4ed3d3517f_k.jpg' />
                    <h3>{singleProduct?.companyName}</h3>
                </div>
                <div className='seller_raiting'>
                    <div>{singleProduct?.rating} <SvgStar /></div>
                    <h3>Uğurlu sifariş</h3>
                </div>
            </div>
        </div>
}
