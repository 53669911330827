import React, { useContext, useState } from 'react'
import { SvgBeMerchant, SvgCart, SvgCategories, SvgClaimPoints, SvgDownloadMobile, SvgHome, SvgLogOut, SvgProfileLogin, SvgSearch, SvgSupport } from '../../assets/icons/Svgs'
import '../../styles/component/primary/Header.css'
import { UserContext } from '../../context/UserContext';
import { merchant } from '../../server/api';
import WebNaviMenu from '../other/WebNaviMenu';

export default function Header({ productList }) {
    const { userInformation } = useContext(UserContext);
    const [isCategoryVisible, setIsCategoryVisible] = useState(true)

    return !userInformation.isMobile ? (
        <div className='header_container'>
            <div className='header_video_cover_container'>
                <div className='video_cover'></div>
                <img className='header_video_cover' src='//live.staticflickr.com/65535/53865111500_4ed3d3517f_k.jpg' />
            </div>


            <div className='header_top'>
                <div onClick={() => window.location.href = '/'} className='header_logo'>
                    <h1>Lucary</h1>
                </div>

                <div style={{ boxShadow: 'none', borderColor: '#ccc', borderWidth: 1, borderStyle: 'solid', height: 30 }} className='header_search_section'>
                    <input placeholder='Axtyaris ..' />

                    <div className='header_search_input'>
                        <div className='header_search_input_placeholders'>
                        </div>

                        <div style={{ width: 40 }} className='header_search_button'>
                            <SvgSearch />
                        </div>
                    </div>
                </div>

                {
                    userInformation?.role === 'GUEST' &&
                    <div className='header_top_navi'>
                        <div onClick={() => window.location.href = 'register'} className='register_btn'>
                            <h3>Qeydiyyat kec</h3>
                        </div>
                        <div onClick={() => window.location.href = 'login'} className='register_btn'>
                            <h3>Daxil ol</h3>
                        </div>
                    </div>
                }


                {
                    userInformation?.role === 'USER' &&
                    <WebNaviMenu userInformation={userInformation} />
                }
            </div>


            <div className='header_second_section'>
                <div className='header_second_section_left' onClick={() => setIsCategoryVisible(!isCategoryVisible)}>
                    <SvgCategories />
                    <h3>Kateqoriyalar</h3>
                </div>


                <div className='header_second_section_right'>
                    <h3 onClick={() => window.location.href = merchant}>Partnyor ol</h3>
                    <h3>Tətbiqi endir</h3>
                    <h3>PUDO-lar</h3>
                    <h3 onClick={() => window.location.href = 'support'}>Dəstək</h3>
                </div>
            </div>

            <div className='header_third_section'>
                <h1>The leading B2B ecommerce <br />platform for global trade</h1>
            </div>
            <div style={{ marginTop: 40 }}></div>
        </div>
    ) :
        (
            <>
                <div className='header_container_mobile'>
                    <img onClick={() => window.location.href = '/'} src={require('../../assets/images/logo.jpg')} />
                    <div className='header_search_bar_mobile'>
                        <SvgSearch />
                        <input placeholder='axtaris' />
                    </div>
                    <div><SvgCart c='#000' /></div>
                    <div><SvgProfileLogin c='#000' /></div>
                </div>

                <div className='mobile_header_navigation_container'>
                    <div className='mobile_header_navigation'>
                        <h3>Bütün Kateqoriyalar</h3>
                        <SvgCategories s={90} />
                    </div>
                    <div onClick={() => window.location.href = merchant} className='mobile_header_navigation'>
                        <h3>Partnyor Ol</h3>
                        <SvgBeMerchant s={90} />
                    </div>
                    <div className='mobile_header_navigation'>
                        <h3>Tətbiqi Endir</h3>
                        <SvgDownloadMobile s={90} />
                    </div>
                    <div className='mobile_header_navigation'>
                        <h3>PUDO Məntəqələri</h3>
                        <SvgClaimPoints s={90} />
                    </div>
                    <div className='mobile_header_navigation'>
                        <h3>Canlı Dəstək</h3>
                        <SvgSupport s={60} />
                    </div>
                </div>
            </>
        )
}
