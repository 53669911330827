import React, { useContext } from 'react'
import '../../styles/component/primary/NavigationBar.css'
import { SvgCart, SvgHome, SvgLogOut, SvgProfileLogin, SvgSearch } from '../../assets/icons/Svgs'
import { UserContext } from '../../context/UserContext';
import { LogOut } from '../../functions/client/LogOut';
import WebNaviMenu from '../other/WebNaviMenu';

export default function NavigationBar(props) {

    const { userInformation } = useContext(UserContext);

    return !userInformation.isMobile ? (
        <div className='navigation_container'>
            <div className='header_top'>
                <div onClick={() => window.location.href = '/'} className='header_logo'>
                    <h1>Lucary</h1>
                </div>
                {
                    !props?.hideSearch &&
                    <div style={{ boxShadow: 'none', borderColor: '#ccc', borderWidth: 1, borderStyle: 'solid', height: 30 }} className='header_search_section'>
                        <input placeholder='Axtaris ..' />

                        <div className='header_search_input'>
                            <div className='header_search_input_placeholders'>
                            </div>

                            <div style={{ width: 40 }} className='header_search_button'>
                                <SvgSearch />
                            </div>
                        </div>
                    </div>
                }

                {
                    userInformation?.role === 'GUEST' &&
                    <>
                        {
                            !props?.hideSearch &&
                            <div className='header_top_navi'>
                                <div onClick={() => window.location.href = 'login'} className='register_btn'>
                                    <h3>Daxil ol</h3>
                                </div>
                                <div onClick={() => window.location.href = 'register'} className='register_btn'>
                                    <h3>Qeydiyyat</h3>
                                </div>
                            </div>
                        }
                    </>
                }

                {
                    userInformation?.role === 'USER' &&
                    <WebNaviMenu userInformation={userInformation} />
                }
            </div>
        </div>
    )
        :
        (
            <>
                <div className='header_container_mobile'>
                    <img onClick={() => window.location.href = '/'} src={require('../../assets/images/logo.jpg')} />
                    <div className='header_search_bar_mobile'>
                        <SvgSearch />
                        <input placeholder='axtaris' />
                    </div>
                    <div><SvgCart c='#000' /></div>
                    <div><SvgProfileLogin c='#000' /></div>
                </div>
            </>
        )
}
