import React, { useEffect, useState } from 'react'
import merchant_info from '../../styles/admin/MerchantControl.module.css'
import { addCategoryToMerchant, getActiveCategoryList, getAllCategoryList, getAllMerchantList, getPendingRequestsProductApi, HOST } from '../../server/api';
import ErrorAlert from '../../functions/other/ErrorAlert';

export default function MerchantControl({ userInformation }) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [merchantInfo, setMerchantInfo] = useState([]);
    const [allowedCategories, setAllowedCategories] = useState([]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [categoryList, setCategoryList] = useState([])

    useEffect(() => {
        (async () => {

            const response = await fetch(`${HOST}${getAllCategoryList}`, {
                method: 'GET',
                headers: {
                    "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json'
                }
            });


            if (!response.ok) {
                ErrorAlert({ response: response })
            }

            setCategoryList(await response.json())

        })()
    }, [])

    useEffect(() => {
        (async () => {

            const response = await fetch(HOST + getAllMerchantList, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${userInformation.jwt}`,
                    "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json'
                }
            });

            setMerchantInfo(await response.json());

        })()

    }, [])



    return (
        <>
            {
                merchantInfo.map((merchant, key) => (
                    <div key={key} className={merchant_info.merchant_container}>
                        <div className={merchant_info.merchant_profile}>
                            <img
                                src={merchant.logoUrl}
                                alt={merchant.companyName}
                                className={merchant_info.profile_image}
                            />
                            <h3 className={merchant_info.merchant_name}>{merchant.companyName}</h3>
                            <button className={merchant_info.details_btn} onClick={openModal}>
                                Detaylar
                            </button>
                        </div>

                        {isModalOpen && (
                            <div className={merchant_info.modal_overlay}>
                                <div className={merchant_info.modal_content}>
                                    <h2>Merchant Kategori İzinleri</h2>

                                    <div className={merchant_info.category_select}>
                                        <select onChange={async e => {

                                            // await fetch(HOST + addCategoryToMerchant + '?accountId=' + 'zlVSQMXJ1sA8JmOvbBqx' + '&categoryId=' + e.target.value, {
                                            await fetch(HOST + addCategoryToMerchant + '?accountId=' + merchant.accountId + '&categoryId=' + e.target.value, {
                                                method: 'PUT',
                                                headers: {
                                                    "Authorization": `Bearer ${userInformation.jwt}`,
                                                    "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                                                    "Access-Control-Allow-Headers": "Content-Type",
                                                    "Access-Control-Allow-Origin": "*",
                                                    'Content-Type': 'application/json'
                                                }
                                            });

                                        }}>
                                            {categoryList.map((category, key) => (
                                                <option key={key} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <ul className={merchant_info.category_list}>
                                        {allowedCategories.map((category, index) => (
                                            <li key={index} className={merchant_info.category_item}>
                                                {category}
                                                <span
                                                    className={merchant_info.remove_icon}
                                                >
                                                    X
                                                </span>
                                            </li>
                                        ))}
                                    </ul>

                                    <button className={merchant_info.close_btn} onClick={closeModal}>
                                        Kapat
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                ))
            }
        </>
    )
}
