import SwiperProductMenu from '../../components/client/SwiperProductMenu'
import { ProductContext } from '../../context/ProductContext'
import { UserContext } from '../../context/UserContext'
import { LineAds, RandomSizeAds } from '../../components/other/Ads'
import Header from '../../components/client/Header'
import Footer from '../../components/client/Footer'
import React, { useContext } from 'react'

import '../../styles/client/page/Home.css'

export default function HomePage() {

  const { productList } = useContext(ProductContext);
  const { userInformation } = useContext(UserContext);

  return (
    <>
      <LineAds />

      <Header productList={productList} />

      {
        productList?.map((p) => (
          <SwiperProductMenu userInformation={userInformation} key={p?.categoryId} p={p} />
        ))
      }

      {/* <RandomSizeAds /> */}

      {/* {
        productList?.map((p) => (
          <PopularMerchants p={p} />
        ))
      } */}

      <Footer userInformation={userInformation} />

    </>
  )
}
