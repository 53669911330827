import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context/UserContext';
import { getUserOrderHistoryListApi, HOST } from '../../../server/api';
import { ToastContainer } from 'react-toastify';
import ErrorAlert from '../../../functions/other/ErrorAlert';

export default function UserOrderHistory() {

    const { userInformation } = useContext(UserContext);

    const [userOrderHistory, setUserOrderHistory] = useState([])


    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(HOST + getUserOrderHistoryListApi, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${userInformation?.jwt}`,
                        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
                        "Access-Control-Allow-Headers": "Content-Type",
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    ErrorAlert({ response: response })
                } else {
                    setUserOrderHistory(await response.json());
                }
            } catch (error) {
                console.log('All User Order History Error: ', error);
            }
        })()
    }, [])



    return (
        <>
            <ToastContainer />

            {
                userOrderHistory?.map(order => (
                    console.log(order),
                    <div className="order-history">
                        <div className="order-card">
                            <div className="card-header">
                                <div className="product-image">
                                    <img src={order?.sale?.imageUrl} alt={order?.sale?.title} />
                                </div>
                                <div className="order-summary">
                                    <h3 className="product-name">{order?.sale?.title}</h3>
                                    <p className="product-price">Qiymeti: {order?.sale?.price}AZN</p>
                                    <p className="order-status preparing">Status: {order?.status}</p>
                                </div>
                            </div>
                            <div className="card-body">
                                <p className="order-number">Sifaris Nomresi: #{order?.id.slice(0, 5)}</p>
                                <p className="product-quantity">Say: {order?.number}</p>
                                <p className="product-category">Kategori: {order?.sale?.categoryId}</p>
                                <p className="seller-info">Satıcı: {order?.sale?.sellerId}</p>
                            </div>
                            <div className="card-footer">
                                <button className="review-order-btn">Sifarisi Qiymetlendir</button>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}
